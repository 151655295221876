import React from 'react'
import Logo from '../imgs/Logo-03-01.svg'
import emailjs from 'emailjs-com'

const Contactanos = ({ onClose }) => {
  const [isMobile, setIsMobile] = React.useState(false)
  const userKey = process.env.REACT_APP_EMAILJS_API_KEY
  const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID
  const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const sendEmail = (e) => {
    e.preventDefault()

    emailjs.init(userKey)

    emailjs.sendForm(serviceID, templateID, e.target).then(
      (result) => {
        console.log(result.text)
        alert('Correo enviado con exito!')
      },
      (error) => {
        console.log(error.text)
        alert('Error al enviar el correo!')
      }
    )
    e.target.reset()
  }

  const containerStyle = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '20px',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '1200px',
    margin: '0 auto',
    paddingTop: '1em',
    paddingBottom: '1em',
    paddingLeft: '5em',
    paddingRight: '5em',
    fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif`,
    backgroundColor: 'white',
  }

  const containerStyleMobile = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '1200px',
    margin: '0 auto',
    paddingTop: '1em',
    paddingBottom: '1em',
    paddingLeft: '1em',
    paddingRight: '1em',
    fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif`,
    backgroundColor: 'white',
  }

  const textStyle = {
    padding: '10px',
    textAlign: 'left',
    paddingTop: '2em',
  }

  const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '1em',
  }

  const inputStyle = {
    padding: '10px',
    marginBottom: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    width: '100%', // Add this line
  }

  const buttonStyle = {
    padding: '10px',
    backgroundColor: '#0a1e3c',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    width: '100%',
  }

  const bannerStyle = {
    backgroundColor: '#00C174',
    height: '20px',
    width: '100%',
  }

  return (
    <div
      style={{
        position: 'sticky',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
      }}
    >
      <div style={isMobile ? containerStyleMobile : containerStyle}>
        <div style={textStyle}>
          <img src={Logo} alt="" height={'96px'} />
          <h1>Póngase en contacto con nuestro equipo de ventas</h1>
          <p>
            Nos encantaría contarte más sobre nuestro software de ventas.
            Complete este formulario y nos pondremos en contacto con usted.
          </p>
        </div>{' '}
        <div style={formStyle}>
          <form onSubmit={sendEmail}>
            <input
              type="text"
              name="from_name"
              placeholder="Nombre"
              required
              style={inputStyle}
            />
            <input
              type="tel"
              name="phone_number"
              placeholder="Número de Teléfono"
              required
              style={inputStyle}
            />
            <input
              type="text"
              name="city"
              placeholder="Ciudad"
              required
              style={inputStyle}
            />
            <input
              type="email"
              name="from_email"
              placeholder="Correo Electrónico"
              required
              style={inputStyle}
            />
            <textarea
              name="message"
              placeholder="Comentario"
              required
              style={inputStyle}
            ></textarea>
            <button type="submit" style={buttonStyle}>
              Enviar
            </button>

            <button
              onClick={onClose}
              style={{
                backgroundColor: 'red',
                color: 'white',
                padding: '1em',
                border: 'none',
                width: '25%',
                marginTop: '2em',
                textAlign: 'center',
                borderRadius: '1em',
              }}
            >
              Cerrar
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Contactanos
