import React, { useState } from 'react'
import { Tabs, Col, Row } from 'antd'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import ventasIcon from '../imgs/cost-icon.svg'
import inventarioIcon from '../imgs/writing-pad-line-icon.svg'
import mermasIcon from '../imgs/testing-icon.svg'
import etiquetasIcon from '../imgs/tags-icon.svg'
import informesIcon from '../imgs/column-chart-icon.svg'
import ventasAnim from '../imgs/ventasAnim.gif'
import inventarioAnim from '../imgs/InventarioAnim.gif'
import mermasAnim from '../imgs/mermasAnim.gif'
import etiquetasAnim from '../imgs/EtiquetasAnim.gif'
import informesAnim from '../imgs/InformesAnim.gif'
import './Funcionalidades.css'
import mermas from '../imgs/mermasV2-01.png'

const Funcionalidades = () => {
  const [activeKey, setActiveKey] = useState('1')
  const [isMobile, setIsMobile] = React.useState(false)
  const [isSmallMobile, setIsSmallMobile] = React.useState(false)
  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024)
      setIsSmallMobile(window.innerWidth <= 600)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  const items = [
    {
      key: '1',
      label: (
        <div style={{ textAlign: 'center' }}>
          <img src={ventasIcon} alt="" height={64} />
          <h3>Ventas</h3>
        </div>
      ),
      children: (
        <Row gutter={12} align={'top'}>
          {' '}
          <Col span={12} className="image-col">
            <img src={ventasAnim} alt="" height={'500px'} />
          </Col>
          <Col span={12} className="text-col">
            <h1 style={{ color: '#00AF4F' }}>Ventas</h1>{' '}
            <h3>Atiende de forma rápida y transparente.</h3>
            <div className="description">
              {' '}
              <p>Busca los productos por nombre, código de barra o imágenes.</p>
              <p> Actualiza de forma simple los precios de venta.</p>
              <p> Deja una venta en pausa y avanza con la siguiente.</p>
              <p>
                {' '}
                Para productos que se venden por kilo, calcula en segundos el
                total con nuestra modalidad de balanza integrada.
              </p>
              <p> Mejora la transparencia en cada venta.</p>
              <p>
                El cliente puede ver cómo se calcula el total a pagar en
                pantalla y con el ticket de detalle.
              </p>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      key: '2',
      label: (
        <div style={{ textAlign: 'center' }}>
          <img src={inventarioIcon} alt="" height={64} />
          <h3>Inventarios</h3>
        </div>
      ),
      children: (
        <Row align={'top'} gutter={12}>
          {' '}
          <Col span={12} className="image-col">
            <img src={inventarioAnim} alt="" height={'500px'} />
          </Col>
          <Col span={12} className="text-col">
            <h1 style={{ color: '#00AF4F' }}>Inventarios</h1>
            <h3>Mantén tus inventarios controlados.</h3>
            <div className="description">
              {' '}
              <p>
                {' '}
                Cada ingreso de mercadería aumenta el stock y cada venta lo
                disminuye.
              </p>
              <p>
                {' '}
                Configura alertas de colores para evitar quiebres de stock.
              </p>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      key: '3',
      label: (
        <div style={{ textAlign: 'center', width: '50%' }}>
          <img src={mermasIcon} alt="" height={64} />
          <h3>Mermas</h3>
        </div>
      ),
      children: (
        <Row align={'top'} gutter={12}>
          {' '}
          <Col span={12} className="image-col">
            <img src={mermas} alt="" height={'500px'} />
          </Col>
          <Col span={12} className="text-col">
            <h1 style={{ color: '#00AF4F' }}>Mermas</h1>
            <h3>
              {' '}
              Lleva un registro de mermas con los productos en mal estado o
              vencidos.
            </h3>
            <div className="description">
              {' '}
              <p>
                Easy Control te ayuda a llevar el registro necesario de manera
                ordenada para que puedas ahorrar en impuestos.
              </p>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      key: '4',
      label: (
        <div style={{ textAlign: 'center' }}>
          <img src={etiquetasIcon} alt="" height={64} />
          <h3>Etiquetas</h3>
        </div>
      ),
      children: (
        <Row align={'top'} gutter={12}>
          {' '}
          <Col span={12} className="image-col">
            <img src={etiquetasAnim} alt="" height={'500px'} />
          </Col>
          <Col span={12} className="text-col">
            <h1 style={{ color: '#00AF4F' }}>Etiquetas</h1>
            <h3>Etiqueta tus productos internamente.</h3>
            <div className="description">
              {' '}
              <p>Easy control crea códigos de barra para tus productos.</p>
              <p>Permite imprimir 12 estilos diferentes de etiquetas.</p>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      key: '5',
      label: (
        <div style={{ textAlign: 'center' }}>
          <img src={informesIcon} alt="" height={64} />
          <h3>Informes</h3>
        </div>
      ),
      children: (
        <Row align={'top'} gutter={12}>
          {' '}
          <Col span={12} className="image-col">
            <img src={informesAnim} alt="" height={'500px'} />
          </Col>
          <Col span={12} className="text-col">
            <h1 style={{ color: '#00AF4F' }}>Informes</h1>
            <h3>
              Conoce lo que está pasando en tu negocio, con solo unos clics.
            </h3>
            <div className="description">
              {' '}
              <p> ¿Qué es lo que más estoy vendiendo?</p>
              <p> ¿Cuánto he vendido de un producto en particular?</p>
              <p> ¿Cuánto he ganado por vender este producto?</p>
              <p> ¿Cuánto he gastado en el negocio este mes o semana?</p>
              <p> ¿Cuánto dinero gané este mes?</p>
            </div>
          </Col>
        </Row>
      ),
    },
  ]

  const itemsMobile = [
    {
      key: '1',
      label: (
        <div
          style={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <img src={ventasIcon} alt="" height={isSmallMobile ? 24 : 32} />
          {isSmallMobile ? <small>Ventas</small> : <p>Ventas</p>}
        </div>
      ),
      children: (
        <Row justify={'center'}>
          {' '}
          <Row>
            <img
              src={ventasAnim}
              alt=""
              height={isSmallMobile ? '280px' : '480px'}
            />
          </Row>
          <Row className="text-mobile">
            <h1 style={{ color: '#00AF4F' }}>Ventas</h1>{' '}
            <h3>Atiende de forma rápida y transparente.</h3>
            <div className="description-mobile">
              {' '}
              <p>Busca los productos por nombre, código de barra o imágenes.</p>
              <p> Actualiza de forma simple los precios de venta.</p>
              <p> Deja una venta en pausa y avanza con la siguiente.</p>
              <p>
                {' '}
                Para productos que se venden por kilo, calcula en segundos el
                total con nuestra modalidad de balanza integrada.
              </p>
              <p> Mejora la transparencia en cada venta.</p>
              <p>
                El cliente puede ver cómo se calcula el total a pagar en
                pantalla y con el ticket de detalle.
              </p>
            </div>
          </Row>
        </Row>
      ),
    },
    {
      key: '2',
      label: (
        <div
          style={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <img src={inventarioIcon} alt="" height={isSmallMobile ? 24 : 32} />
          {isSmallMobile ? <small>Inventarios</small> : <p>Inventarios</p>}
        </div>
      ),
      children: (
        <Row justify={'center'}>
          {' '}
          <Row>
            <img
              src={inventarioAnim}
              alt=""
              height={isSmallMobile ? '280px' : '480px'}
            />
          </Row>
          <Row className="text-mobile">
            <h3 style={{ color: '#00AF4F' }}>Inventarios</h3>
            <h3>Mantén tus inventarios controlados.</h3>
            <div className="description-mobile">
              {' '}
              <p>
                {' '}
                Cada ingreso de mercadería aumenta el stock y cada venta lo
                disminuye.
              </p>
              <p>
                {' '}
                Configura alertas de colores para evitar quiebres de stock.
              </p>
            </div>
          </Row>
        </Row>
      ),
    },
    {
      key: '3',
      label: (
        <div
          style={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <img src={mermasIcon} alt="" height={isSmallMobile ? 24 : 32} />
          {isSmallMobile ? <small>Mermas</small> : <p>Mermas</p>}
        </div>
      ),
      children: (
        <Row justify={'center'}>
          {' '}
          <Row>
            <img
              src={mermas}
              alt=""
              height={isSmallMobile ? '280px' : '480px'}
            />
          </Row>
          <Row className="text-mobile">
            <h1 style={{ color: '#00AF4F' }}>Mermas</h1>
            <h3>
              {' '}
              Lleva un registro de mermas con los productos en mal estado o
              vencidos.
            </h3>
            <div className="description-mobile">
              <p>
                Easy Control te ayuda a llevar el registro necesario de manera
                ordenada para que puedas ahorrar en impuestos.
              </p>
              <p>¡Ahorra en impuestos!</p>
            </div>
          </Row>
        </Row>
      ),
    },
    {
      key: '4',
      label: (
        <div
          style={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <img src={etiquetasIcon} alt="" height={isSmallMobile ? 24 : 32} />
          {isSmallMobile ? <small>Etiquetas</small> : <p>Etiquetas</p>}
        </div>
      ),
      children: (
        <Row justify={'center'}>
          {' '}
          <Row>
            <img
              src={etiquetasAnim}
              alt=""
              height={isSmallMobile ? '280px' : '480px'}
            />
          </Row>
          <Row className="text-mobile">
            <h1 style={{ color: '#00AF4F' }}>Etiquetas</h1>
            <h3>Etiqueta tus productos internamente.</h3>
            <div className="description-mobile">
              {' '}
              <p>Etiqueta tus productos internamente.</p>
              <p>Easy control crea códigos de barra para tus productos.</p>
              <p>Permite imprimir 12 estilos diferentes de etiquetas.</p>
            </div>
          </Row>
        </Row>
      ),
    },
    {
      key: '5',
      label: (
        <div
          style={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <img src={informesIcon} alt="" height={isSmallMobile ? 24 : 32} />
          {isSmallMobile ? <small>Informes</small> : <p>Informes</p>}
        </div>
      ),
      children: (
        <Row justify={'center'}>
          {' '}
          <Row>
            <img
              src={informesAnim}
              alt=""
              height={isSmallMobile ? '280px' : '480px'}
            />
          </Row>
          <Row className="text-mobile">
            <h1 style={{ color: '#00AF4F' }}>Informes</h1>
            <h3>
              Conoce lo que está pasando en tu negocio, con solo unos clics.
            </h3>
            <div className="description-mobile">
              {' '}
              <p> ¿Qué es lo que más estoy vendiendo?</p>
              <p> ¿Cuánto he vendido de un producto en particular?</p>
              <p> ¿Cuánto he ganado por vender este producto?</p>
              <p> ¿Cuánto he gastado en el negocio este mes o semana?</p>
              <p> ¿Cuánto dinero gané este mes?</p>
            </div>
          </Row>
        </Row>
      ),
    },
  ]
  const CustomTabsWrapper = ({ children, prevArrow, nextArrow }) => {
    const handlePrevArrowClick = () => {
      const prevIndex = items.findIndex((item) => item.key === activeKey) - 1
      const prevKey = items[prevIndex]?.key || items[items.length - 1]?.key
      setActiveKey(prevKey)
    }

    const handleNextArrowClick = () => {
      const nextIndex = items.findIndex((item) => item.key === activeKey) + 1
      const nextKey = items[nextIndex]?.key || items[0]?.key
      setActiveKey(nextKey)
    }

    return (
      <>
        {isMobile ? (
          <div className="custom-tabs-arrow-m">
            <div onClick={handlePrevArrowClick}>{prevArrow}</div>
            {children}
            <div onClick={handleNextArrowClick}>{nextArrow}</div>
          </div>
        ) : (
          <div className="custom-tabs-wrapper">
            <div
              className="custom-tabs-arrow left"
              onClick={handlePrevArrowClick}
            >
              {prevArrow}
            </div>
            {children}
            <div
              className="custom-tabs-arrow right"
              onClick={handleNextArrowClick}
            >
              {nextArrow}
            </div>
          </div>
        )}
      </>
    )
  }

  const onChange = (key) => {
    setActiveKey(key)
  }

  return (
    <div className="funcionalidades">
      <h1
        style={{ textAlign: 'center', fontSize: '64px', paddingBottom: '1em' }}
      >
        ¿En qué me puede <span style={{ color: '#00AF4F' }}>ayudar?</span>
      </h1>
      {isMobile ? (
        <CustomTabsWrapper>
          <Tabs
            centered
            tabBarGutter={isSmallMobile ? 16 : 24}
            defaultActiveKey={activeKey}
            activeKey={activeKey}
            onChange={onChange}
            tabPosition="top"
          >
            {itemsMobile.map((item) => (
              <Tabs.TabPane key={item.key} tab={item.label}>
                {item.children}
              </Tabs.TabPane>
            ))}
          </Tabs>
        </CustomTabsWrapper>
      ) : (
        <CustomTabsWrapper
          prevArrow={<FaAngleLeft />}
          nextArrow={<FaAngleRight />}
        >
          <Tabs
            centered
            tabBarGutter={128}
            defaultActiveKey={activeKey}
            activeKey={activeKey}
            onChange={onChange}
            tabPosition="bottom"
          >
            {items.map((item) => (
              <Tabs.TabPane key={item.key} tab={item.label}>
                {item.children}
              </Tabs.TabPane>
            ))}
          </Tabs>
        </CustomTabsWrapper>
      )}
    </div>
  )
}

export default Funcionalidades
