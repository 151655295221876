import React, { useState } from 'react'
import BigBanner from './BigBanner'
import Funcionalidades from './Funcionalidades'
import Testimonios from './Testimonios'
import Contacto from './Contacto'
import Precios from './Precios'
import Contactanos from './Contactanos'
import { Element } from 'react-scroll'

const Inicio = () => {
  const [showForm, setShowForm] = useState(false)
  const [isMobile, setIsMobile] = React.useState(false)
  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 500)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  return (
    <>
      <Element name="inicio">
        <BigBanner />
      </Element>
      <Element name="funcionalidades">
        <Funcionalidades />
      </Element>
      <Element name="testimonios">
        <Testimonios />
      </Element>
      <Element name="precios">
        <Precios />
        {isMobile ? (
          <>
            {' '}
            <h2
              style={{
                textAlign: 'center',
                paddingLeft: '10vw',
                paddingRight: '10vw',
                paddingTop: '5vw',

                color: '#0a1e3c',
                textTransform: 'uppercase',
                overflow: 'hidden',
              }}
            >
              Hacemos sencillo el proceso de migración digital.
            </h2>
            <h2
              style={{
                textAlign: 'center',
                paddingLeft: '10vw',
                paddingRight: '10vw',
                paddingBottom: '5vw',
                color: '#0a1e3c',
                textTransform: 'uppercase',
                overflow: 'hidden',
              }}
            >
              <span style={{ color: '#00AF4F' }}>
                Vamos presencialmente a tu negocio
              </span>
              , creamos los productos por ti, capacitamos en persona y con apoyo
              de tutoriales.
            </h2>
          </>
        ) : (
          <>
            {' '}
            <h1
              style={{
                textAlign: 'center',
                paddingLeft: '10vw',
                paddingRight: '10vw',
                paddingTop: '5vw',

                color: '#0a1e3c',
                textTransform: 'uppercase',
                overflow: 'hidden',
              }}
            >
              Hacemos sencillo el proceso de migración digital.
            </h1>
            <h1
              style={{
                textAlign: 'center',
                paddingLeft: '10vw',
                paddingRight: '10vw',
                paddingBottom: '5vw',
                color: '#0a1e3c',
                textTransform: 'uppercase',
                overflow: 'hidden',
              }}
            >
              <span style={{ color: '#00AF4F' }}>
                Vamos presencialmente a tu negocio
              </span>
              , creamos los productos por ti, capacitamos en persona y con apoyo
              de tutoriales.
            </h1>
          </>
        )}
      </Element>
      <Element name="contacto">
        <Contacto setShowForm={setShowForm} showForm={showForm} />
      </Element>

      {/*<Contactanos showForm={showForm} />*/}
    </>
  )
}

export default Inicio
