import React, { useState } from 'react'
import { Card, Avatar, Rate } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import './Testimonios.css'

const { Meta } = Card

const Testimonios = () => {
  const [showMore, setShowMore] = useState('Mostrar Mas')
  function handleClick() {
    if (showMore == 'Mostrar Mas') {
      setShowMore('Mostrar Menos')
    } else {
      setShowMore('Mostrar Mas')
    }
  }
  return (
    <div className='testimonios-background'>
      <div className='testimonios-wrapper'>
        <h1
          style={{
            fontSize: '48px',
            color: '#0a1e3c',
            paddingBottom: '1em',
            textAlign: 'center',
            padding: '1em',
          }}
        >
          Lo que opinan nuestros{' '}
          <span style={{ color: '#00AF4F' }}>clientes.</span>
        </h1>
        <div className='testimonios-container'>
          <Card className='testimonial-card'>
            <Meta
              avatar={<Avatar icon={<UserOutlined />} />}
              title={
                <>
                  Cecilia Montecinos
                  <br />
                  {<small>Tostaduría el tencito</small>}
                </>
              }
              extra={<p>lorem</p>}
              description={
                showMore == 'Mostrar Mas'
                  ? 'Podemos atender rápidamente en el negocio con el sistema y sin problemas. Queda más fácil y rápido ver el total de las ventas y estoy implementando inventarios...'
                  : 'Podemos atender rápidamente en el negocio con el sistema y sin problemas. Queda más fácil y rápido ver el total de las ventas y estoy implementando inventarios. Es muy bueno, y continúo aprendiendo para sacarle provecho a todo lo que ofrece.'
              }
            />
            <div className='show-more'>
              {' '}
              <small onClick={handleClick}>{showMore}</small>
            </div>

            <div className='star-rating'>
              <Rate defaultValue={5} disabled />
            </div>
          </Card>
          <Card className='testimonial-card'>
            <Meta
              avatar={<Avatar icon={<UserOutlined />} />}
              title={
                <>
                  Gustavo Lefimil
                  <br />
                  {<small>Panadería Lefimil</small>}
                </>
              }
              description='Puedo estar más tranquilo que antes. Ya que tenemos más claridad de los precios y ha sido fundamental en mejorar mis ganancias. Ahora somos más transparentes con nuestros clientes. El sistema ayuda mucho.'
            />
            <div className='star-rating'>
              <Rate defaultValue={5} disabled />
            </div>
          </Card>
          <Card className='testimonial-card'>
            <Meta
              avatar={<Avatar icon={<UserOutlined />} />}
              title={
                <>
                  Kathia Varela
                  <br />
                  {<small>Almacen Roka</small>}
                </>
              }
              description='Tenemos más claridad de precios, podemos darles más transparencia a los clientes y tenemos mayor orden en lo que nos deben. El sistema nos ha ayudado mucho.'
            />
            <div className='star-rating'>
              <Rate defaultValue={5} disabled />
            </div>
          </Card>
          <Card className='testimonial-card'>
            <Meta
              avatar={<Avatar icon={<UserOutlined />} />}
              title={
                <>
                  Antonia
                  <br />
                  {/*<small>Mar 12, 2023</small>*/}
                </>
              }
              description='El sistema nos ayuda a ser más transparentes con nuestros clientes, mostramos en pantalla como se calcula todo y le entregamos un detalle. El soporte técnico es muy bueno, nos han dado respuesta rápida frente a cualquier problema o duda.'
            />
            <div className='star-rating'>
              <Rate defaultValue={5} disabled />
            </div>
          </Card>
          <Card className='testimonial-card'>
            <Meta
              avatar={<Avatar icon={<UserOutlined />} />}
              title={
                <>
                  Yennifer
                  <br />
                  {/*<small>Mar 12, 2023</small>*/}
                </>
              }
              description={
                showMore == 'Mostrar Mas'
                  ? ' Soy graduada en Administración, con más de 18años de experiencia en administración y 5 en auditoría, y trabajar con *Easy control* es la manera más fácil y simplificada de llevar ordenadamente tus inventarios, costos y ventas...'
                  : ' Soy graduada en Administración, con más de 18años de experiencia en administración y 5 en auditoría, y trabajar con *Easy control* es la manera más fácil y simplificada de llevar ordenadamente tus inventarios, costos y ventas, y simultáneamente permite realizar seguimientos post-ventas y te brinda todas las herramientas para realizar auditorías diarias, semanales o mensuales, permitiendo llevar un control automático en un solo click, lo recomiendo 💯%  con Easy-Control Adiós a las notas y cuadernos.'
              }
            />
            <div className='show-more'>
              {' '}
              <small onClick={handleClick}>{showMore}</small>
            </div>

            <div className='star-rating'>
              <Rate defaultValue={5} disabled />
            </div>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default Testimonios
