import React, { useState } from "react";
import { Link } from "react-scroll";
import {
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import "./Footer.css";
import Logo from "../imgs/Logo.png";

const Footer = () => {
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <img src={Logo} alt="" height="64px" />
        </div>
        {isMobile ? (
          ""
        ) : (
          <nav className="footer-links">
            <Link to="inicio" smooth={true} duration={800}>
              Inicio
            </Link>
            <Link to="funcionalidades" smooth={true} duration={800}>
              Funcionalidades
            </Link>
            <Link to="precios" smooth={true} duration={800}>
              Precios
            </Link>
            <Link to="testimonios" smooth={true} duration={800}>
              Testimonios
            </Link>
            <Link to="contacto" smooth={true} duration={800}>
              Contacto
            </Link>
          </nav>
        )}

        <nav className={isMobile ? "footer-links-mobile" : "footer-links"}>
          <a href="#">Política de privacidad</a>
          <a href="#">Términos de servicio</a>
        </nav>
        <div className="social-icons-mobile">
          <FacebookOutlined className="social-icon" />
          <InstagramOutlined className="social-icon" />
          <TwitterOutlined className="social-icon" />
        </div>
      </div>
      <div className="footer-copyright">
        © {new Date().getFullYear()} EasyControl. Todos los derechos reservados.
      </div>
    </footer>
  );
};

export default Footer;
