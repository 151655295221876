import logo from "./logo.svg";
import "./App.css";
import Inicio from "./components/Inicio";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import WhatsAppIcon from "./components/WhatsAppIcon";

function App() {
  return (
    <div>
      <Navbar />
      <Inicio />
      <Footer />
      <WhatsAppIcon />
    </div>
  );
}

export default App;
