import React, { useState, useEffect } from 'react'
import { WhatsAppOutlined } from '@ant-design/icons'
import './WhatsAppIcon.css'

const WhatsAppIcon = () => {
  const [iconClass, setIconClass] = useState('whatsapp-icon')

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset
      const windowHeight = window.innerHeight
      const bodyHeight = document.body.offsetHeight

      if (scrollPosition + windowHeight >= bodyHeight) {
        setIconClass('whatsapp-icon whatsapp-icon-offset')
      } else {
        setIconClass('whatsapp-icon')
      }
    }

    window.addEventListener('scroll', handleScroll)
    handleScroll()

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <a
      href="https://wa.me/+56981589840" // Replace YOUR_PHONE_NUMBER with your phone number
      target="_blank"
      rel="noopener noreferrer"
      className={iconClass}
    >
      <WhatsAppOutlined />
    </a>
  )
}

export default WhatsAppIcon
