import React, { useState, useEffect } from 'react'
import { Button } from 'antd'
import './Contacto.css'
import ReactDOM from 'react-dom/client'
import Contactanos from './Contactanos'
import bannerMobile from '../imgs/Banners-01-05v.png'
import bannerDesktopA from '../imgs/Banners-01-05.png'
import bannerDesktopB from '../imgs/Banners-01-04v.png'

const Contacto = ({ setShowForm, showForm }) => {
  const [showModal, setShowModal] = useState(false)
  const [isMobile, setIsMobile] = React.useState(false)
  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  const openPopup = (e) => {
    e.preventDefault()
    setShowModal(true)
  }
  const closePopup = () => {
    setShowModal(false)
  }
  /*
  function openPopup(e) {
    e.preventDefault();

    const popupWidth = 1000;
    const popupHeight = 420;

    // Calculate the position to center the popup window
    const left = window.screen.width / 2 - popupWidth / 2;
    const top = window.screen.height / 2 - popupHeight / 2;

    // Create a new window with centered position
    const popupWindow = window.open(
      "",
      "",
      `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`
    );

    // Ensure the popup is not blocked
    if (popupWindow == null || typeof popupWindow == "undefined") {
      alert(
        "Por favor apaga tu pop-up blocker y haz click en el link nuevamente."
      );
      return;
    }

    // Create root container in the new window
    const root = popupWindow.document.createElement("div");
    popupWindow.document.body.appendChild(root);

    // Use createRoot for concurrent mode in React 17+
    ReactDOM.createRoot(root).render(<Contactanos />);
  }
*/
  const divStyle = {
    backgroundImage: `url(${isMobile ? bannerMobile : bannerDesktopA})`,
    position: 'relative',
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: ' 60em auto',
    paddingTop: '5em',
    paddingbottom: '20em',
    display: 'flex',
    justifycontent: 'center',
    alignContent: 'baseline',
  }
  return (
    <>
      {showModal && <Contactanos onClose={closePopup} />}
      <div className="contacto-container" style={divStyle}>
        <div className="contacto-content">
          <div class="flex-grid-contact">
            <div class="col-contact">
              {' '}
              <h1>Pónganse en contacto con nuestro equipo de ventas.</h1>
              <Button
                type="primary"
                className="contacto-button"
                onClick={(e) => openPopup(e)}
              >
                Contáctenos
              </Button>
            </div>
            <div class="col"></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contacto
