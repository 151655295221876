import React, { useState } from "react";
import { Link } from "react-scroll";
import "./Navbar.css";
import Logo from "../imgs/Logo-02-01.svg";
import { WhatsAppOutlined } from "@ant-design/icons";
import "./WhatsAppIcon.css";
import ReactDOM from "react-dom/client";
import Contactanos from "./Contactanos";

const Navbar = () => {
  const [isMobile, setIsMobile] = React.useState(false);
  const [isSmallMobile, setSmallIsMobile] = React.useState(false);
  const [sideMenuState, setSideMenuState] = useState("menu-mobile");
  const [showModal, setShowModal] = useState(false);

  const openPopup = (e) => {
    e.preventDefault();
    setShowModal(true);
  };
  const closePopup = () => {
    setShowModal(false);
  };

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1200);
      setSmallIsMobile(window.innerWidth < 425);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function burgerButton(e) {
    e.preventDefault();
    if (sideMenuState === "menu-mobile") {
      setSideMenuState("menu-mobile-open");
    } else {
      setSideMenuState("menu-mobile");
    }
  }
  /*
  function openPopup(e) {
    e.preventDefault();

    const popupWidth = 1000;
    const popupHeight = 420;

    // Calculate the position to center the popup window
    const left = window.screen.width / 2 - popupWidth / 2;
    const top = window.screen.height / 2 - popupHeight / 2;

    // Create a new window with centered position
    const popupWindow = window.open(
      "",
      "",
      `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`
    );

    // Ensure the popup is not blocked
    if (popupWindow == null || typeof popupWindow == "undefined") {
      alert(
        "Por favor apaga tu pop-up blocker y haz click en el link nuevamente."
      );
      return;
    }

    // Create root container in the new window
    const root = popupWindow.document.createElement("div");
    popupWindow.document.body.appendChild(root);

    // Use createRoot for concurrent mode in React 17+
    ReactDOM.createRoot(root).render(<Contactanos />);
  }
*/
  const navLinks = (
    <>
      {isMobile ? (
        <img
          src={Logo}
          alt=""
          height={"40px"}
          style={{ padding: "1em", position: "absolute", top: "5%" }}
        />
      ) : (
        ""
      )}

      <Link to="inicio" smooth={true} duration={800} className="menu-link">
        Inicio
      </Link>
      <Link
        to="funcionalidades"
        smooth={true}
        duration={500}
        className="menu-link"
      >
        Funcionalidades
      </Link>
      <Link to="testimonios" smooth={true} duration={800} className="menu-link">
        Testimonios
      </Link>
      <Link to="precios" smooth={true} duration={800} className="menu-link">
        Precios
      </Link>
      <Link to="contacto" smooth={true} duration={800} className="menu-link">
        Contacto
      </Link>
    </>
  );

  return (
    <>
      {" "}
      <header className="header">
        <span className="logo">
          <img src={Logo} alt="" height={"54px"} />
        </span>

        {!isMobile ? (
          <>
            <nav className="menu">{navLinks}</nav>
            <p className="cta-button" onClick={(e) => openPopup(e)}>
              Hablemos!
            </p>
          </>
        ) : !isSmallMobile ? (
          <>
            <div className="hamburger">
              <span onClick={(e) => burgerButton(e)}>&#9776;</span>
            </div>
            <nav className={`menu-mobile ${sideMenuState}`}>{navLinks}</nav>
            <p className="cta-button" onClick={(e) => openPopup(e)}>
              Hablemos!
            </p>
          </>
        ) : (
          <>
            {" "}
            <div className="hamburger">
              <span onClick={(e) => burgerButton(e)}>&#9776;</span>
            </div>
            <nav className={`menu-mobile ${sideMenuState}`}>{navLinks}</nav>
          </>
        )}
      </header>
      {showModal && <Contactanos onClose={closePopup} />}
    </>
  );
};

export default Navbar;
