import React, { useState } from "react";
import "./BigBanner.css";
import bannerImage from "../imgs/Banners-01-01.png";
import bannerImageMobile from "../imgs/Banners-01-02.png";
import ReactDOM from "react-dom/client";
import Contactanos from "./Contactanos";

const BigBanner = () => {
  const [showModal, setShowModal] = useState(false);

  const [isMobile, setIsMobile] = React.useState(false);
  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 500);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  /*
  function openPopup(e) {
    e.preventDefault();

    const popupWidth = 1000;
    const popupHeight = 420;

    // Calculate the position to center the popup window
    const left = window.screen.width / 2 - popupWidth / 2;
    const top = window.screen.height / 2 - popupHeight / 2;

    // Create a new window with centered position
    const popupWindow = window.open(
      "",
      "",
      `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`
    );

    // Ensure the popup is not blocked
    if (popupWindow == null || typeof popupWindow == "undefined") {
      alert(
        "Por favor apaga tu pop-up blocker y haz click en el link nuevamente."
      );
      return;
    }



    // Create root container in the new window
    const root = popupWindow.document.createElement("div");
    popupWindow.document.body.appendChild(root);

    // Use createRoot for concurrent mode in React 17+
    ReactDOM.createRoot(root).render(<Contactanos />);
  }
  */
  const openPopup = (e) => {
    e.preventDefault();
    setShowModal(true);
  };
  const closePopup = () => {
    setShowModal(false);
  };

  return (
    <>
      {showModal && <Contactanos onClose={closePopup} />}
      <div
        className="big-banner"
        style={{
          backgroundImage: isMobile
            ? `url(${bannerImageMobile})`
            : `url(${bannerImage})`,
        }}
      >
        <div className="big-banner-content">
          <h1>
            Somos un software diseñado a medida para
            <span style={{ color: "#00AF4F" }}> Verdulerías </span> y
            <span style={{ color: "#00AF4F" }}> Almacenes.</span>
          </h1>
          <p>
            Esta hecho para ser rápido, simple y para convertirse en una gran
            herramienta en tu negocio.
          </p>
          <button className="easy-control-btn" onClick={openPopup}>
            Prueba EasyControl!
          </button>
        </div>
      </div>
    </>
  );
};

export default BigBanner;
