import React from "react";
import { Card, Row, Col } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import "./Precios.css";

const Precios = () => {
  const features = [
    "Caracteristicas",
    "Punto de Venta",
    "Busqueda por Imagenes",
    "Apertura y Cierre de Cajas",
    "Creacion de Productos y Categorias",
    "Ajuste de Precios",
    "Ingreso Simple mercaderia",
    "Resgistro de Facturas",
    "Inventario",
    "Registro de Mermas",
    "Ingreso de Gastos",
    "Informe Ventas Diarias",
    "Infrome Margenes",
    "Infrome Gastos",
    "Informe Utilidades",
    "Usuarios y Permisos",
    "Subscripcion",
  ];

  const basicPlan = [
    "Plan Basico",
    true,
    true,
    false,
    true,
    true,
    false,
    false,
    false,
    false,
    false,
    true,
    true,
    false,
    false,
    true,
    "0.6 UF + IVA",
  ];

  const completePlan = [
    "Plan Completo",
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    "1 UF + IVA",
  ];
  const basicPlanMobil = [
    "Basico",
    true,
    true,
    false,
    true,
    true,
    false,
    false,
    false,
    false,
    false,
    true,
    true,
    false,
    false,
    true,
    "0.6 UF",
  ];

  const completePlanMobil = [
    "Completo",
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    "1 UF",
  ];
  const [isMobile, setIsMobile] = React.useState(false);
  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const renderRow = (text, basic, complete, isLastRow, isFirstRow) => (
    <Row className="price-row">
      <Col span={8}>
        {isFirstRow || isLastRow ? (
          isLastRow ? (
            <h3>{text}</h3>
          ) : isMobile ? (
            <h4>{text}</h4>
          ) : (
            <h2>{text}</h2>
          )
        ) : (
          <p>
            {" "}
            <span>
              <FaAngleRight />
            </span>
            {text}
          </p>
        )}
      </Col>
      <Col span={8} className="plan-col">
        {isFirstRow || isLastRow ? (
          isLastRow ? (
            isMobile ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h3
                  style={{
                    margin: "0px",
                  }}
                >
                  {basic}
                </h3>
                <h3
                  style={{
                    margin: "0px",
                  }}
                >
                  +IVA
                </h3>
              </div>
            ) : (
              <h3>{basic}</h3>
            )
          ) : isMobile ? (
            <h4
              style={{
                backgroundColor: "#0a1e3c",
                border: "solid",
                borderWidth: "10px",
                borderColor: "#0a1e3c",
                borderRadius: "10px",
                color: "white",
              }}
            >
              {basic}
            </h4>
          ) : (
            <h2
              style={{
                backgroundColor: "#0a1e3c",
                border: "solid",
                borderWidth: "10px",
                borderColor: "#0a1e3c",
                borderRadius: "10px",
                color: "white",
              }}
            >
              {basic}
            </h2>
          )
        ) : basic ? (
          <CheckOutlined style={{ color: "green", fontSize: "24px" }} />
        ) : (
          <CloseOutlined style={{ color: "red", fontSize: "24px" }} />
        )}
      </Col>
      <Col span={8} className="plan-col">
        {isFirstRow || isLastRow ? (
          isLastRow ? (
            isMobile ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h3
                  style={{
                    margin: "0px",
                  }}
                >
                  {complete}
                </h3>
                <h3
                  style={{
                    margin: "0px",
                  }}
                >
                  +IVA
                </h3>
              </div>
            ) : (
              <h3>{complete}</h3>
            )
          ) : isMobile ? (
            <h4
              style={{
                backgroundColor: "#00C174",
                border: "solid",
                borderWidth: "10px",
                borderColor: "#00C174",
                borderRadius: "10px",
                color: "white",
              }}
            >
              {complete}
            </h4>
          ) : (
            <h2
              style={{
                backgroundColor: "#00C174",
                border: "solid",
                borderWidth: "10px",
                borderColor: "#00C174",
                borderRadius: "10px",
                color: "white",
              }}
            >
              {complete}
            </h2>
          )
        ) : complete ? (
          <CheckOutlined style={{ color: "green", fontSize: "24px" }} />
        ) : (
          <CloseOutlined style={{ color: "red", fontSize: "24px" }} />
        )}
      </Col>
    </Row>
  );

  return (
    <div className="precios-wrapper">
      <h1
        style={{
          textAlign: "center",
          padding: "1em",
          color: "white",
          fontSize: "48px",
        }}
      >
        Planes y <span style={{ color: "#00AF4F" }}>Precios</span>
      </h1>
      {isMobile ? (
        <Card className="custom-card">
          {features.map((feature, index) =>
            renderRow(
              feature,
              basicPlanMobil[index],
              completePlanMobil[index],
              index === features.length - 1,
              index === 0
            )
          )}
        </Card>
      ) : (
        <Card className="custom-card">
          {features.map((feature, index) =>
            renderRow(
              feature,
              basicPlan[index],
              completePlan[index],
              index === features.length - 1,
              index === 0
            )
          )}
        </Card>
      )}
    </div>
  );
};

export default Precios;
